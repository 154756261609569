
import { TbCameraPlus} from "react-icons/tb";
import React, { useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Logout(props) {


const { t ,i18n} = useTranslation();


  return (
  <>
    <div onClick={()=>{ props.setModal(false)}} className="modalOverlay"></div>
    <div  className="imgModal" >
 <p id="logoutTitle">{t("dashboard.logout")}</p>
 <button id="confirm" onClick={()=>{ props.logout(); props.setModal(false); }}>{t("end.confirm")}</button>

 <span id="btnSpace"></span>
 <button id="cancel" onClick={()=>{ props.setModal(false)}}>{t("dashboard.cancel")}</button>

      <div >
          </div></div></> 
  );
}

export default Logout;
