
import { TbCameraPlus} from "react-icons/tb";
import React, { useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { end } from "../api";
import { DotLoader } from 'react-spinners';

function UploadImg(props) {

  const [image, setImage] = useState(null);
const cameraRef=useRef()
const { t ,i18n} = useTranslation();
const token=  localStorage.getItem("token")
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [loading, setLoading]=useState(false);

const [msgAr, setMsgAr]=useState("");
const [msgEn, setMsgEn]=useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setImage(e.target.result);
      setErrorMsgModel(false)
    };

    reader.readAsDataURL(file);
  };

function endOrder(){
  const formData = new FormData()
if(!cameraRef.current.files[0]){
  setErrorMsgModel(true)
  setMsgAr("يرجى تحميل الصورة للمتابعة")
  setMsgEn("Please upload image to continue")
}
else{
  setLoading(true)

  formData.append('deliveryImage',cameraRef.current.files[0])

  end(formData,props.id,token,i18n.language).then((response) => {
    props.setEnded(true); props.setModal(false); props.setSuccess(true)

  }).catch((error)=>{
    setErrorMsgModel(true)
    console.log(error)
    
  setMsgAr(error.response.data.errors.deliveryImage[0])
  setMsgEn(error.response.data.errors.deliveryImage[0])
  setLoading(false)

  })}
}

  return (
  <>
    <div onClick={()=>{ props.setModal(false)}} className="modalOverlay"></div>
    <div  className="imgModal" >
 <p id="modalTitle">{t("end.sure")}</p>
 <p style={image?{marginBottom:"1em"}:null} id="modalText">{t("end.upload")}</p>
 {image? <img src={image}/>:null}
 <button id="uploadBtn" disabled={loading} onClick={()=>
    cameraRef.current.click()
    }> {t("end.capture")} <TbCameraPlus size={27}/></button>

 <input id="cameraInput" type="file" ref={cameraRef} capture="camera" accept="image/*" onChange={handleImageUpload} />

 {i18n.language=="ar"?
<span className="errMsg">{errorMsgModel? msgAr:null}</span>:
<span className="errMsg" style={{marginTop:"1em",textAlign:"center"}}>{errorMsgModel? msgEn:null}</span>}

{loading?<div className="loaderDiv" style={{height: "50px",marginTop:"1em",flexDirection:"column"}}>

<DotLoader size={30} color="#ED9434"/>
<p style={{marginTop:"1em"}}>{t("end.uploadLoad")}</p>
</div>:<>
<button  id="confirm" onClick={endOrder}>{t("end.confirm")}</button>

 <span id="btnSpace"></span>
 <button id="cancel" onClick={()=>{ props.setModal(false)}}>{t("end.decline")}</button>
 </>}
      </div></> 
  );
}

export default UploadImg;
