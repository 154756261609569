
import React, { useState,useRef } from 'react';
import Ellipse from '../assets/Ellipse.svg';
import { useTranslation } from 'react-i18next';

function SuccessModal(props) {
  const { t ,i18n} = useTranslation();

  return (
  <>
    <div onClick={()=>{ props.setModal(false)}} className="modalOverlay"></div>
    <div   className={i18n.language=="ar"?"imgModal successModal":"imgModal successModal modalEn"}  >
       <div style={{position:'relative'}}>
        <div id="ellipse"  style={{ backgroundImage: `url(${Ellipse})` }}>

        </div>
 <p id="modalTitle">{t("end.delivered")} </p>

 <button id="cancel" onClick={()=>{ props.setModal(false)}}>{t("end.accept")}</button>
   <div ></div>
          </div></div></> 
  );
}

export default SuccessModal;
