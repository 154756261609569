import '../styles/orders.css';
import { useEffect } from 'react';
import { FiSearch } from "react-icons/fi";
import { GrMapLocation} from "react-icons/gr";
import { useState } from 'react';
import Navbar from '../components/Navbar';
import { RiRecordCircleFill} from "react-icons/ri";
import {IoCheckmarkCircle} from "react-icons/io5"
import { RxDotFilled} from "react-icons/rx";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { DotLoader } from 'react-spinners';
import { getAllOrders } from '../api';
import { GrNotes} from "react-icons/gr";

function Orders() {
    const[modal,setModal]=useState(false)
    const[loading,setLoading]=useState(true)
    const[orders,setOrders]=useState([])
    const token=  localStorage.getItem("token")
    const userName= localStorage.getItem("name")
    const [searchTerm,SetSearchTerm]=useState('');
    const [notFound,setNotFound]=useState(false);
    const [orderDetails,setOrderDetails]=useState([]);

    const { t,i18n} = useTranslation();

    useEffect(() => {
        getAllOrders(token).then((response) => {
            setOrders(response.data.data)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)

        })
       
     },[]) 
     

     function handleSearch(e){
        SetSearchTerm(e.target.value)
      
      
       const order= orders && orders.filter((data)=>{
          if(e.target.value===""){
              return data;
          } 
          else if(data.orderId.toString().includes(e.target.value.toLowerCase())){
         
         return data
         }
        
        
       })
      if(order.length==0){
        setNotFound(true) 
      console.log("true")
      }
      else{
        setNotFound(false) 
        console.log("false")
        console.log(order)

      }
      }

      function showDetails(id){
          let idsArr=orderDetails;
            if (idsArr.includes(id)) {
              // Item exists in array, remove it
             setOrderDetails( idsArr.filter((item) => item !== id))
            } else {
              // Item doesn't exist in array, add it
              setOrderDetails( idsArr.concat(id))
            }
         
      }
     return (
<div>
  
<Navbar/>
{loading?<div className="loaderDiv" style={{height: "80vh"}}>

<DotLoader size={50} color="#ED9434"/></div>:
<div className={i18n.language=="ar"?'container':'container dashboardEn' }>
<div id="srchDiv"> 
        <p id="hello">{t("dashboard.hello")}   {userName} </p>
        <p id="look">{t("dashboard.check")}</p>
        <div id="srchInput">
        <FiSearch size={20}  color='#667085'/>

<input  placeholder={t("dashboard.enterOrder")} onChange={(e)=>handleSearch(e)}/>
        </div>
      {/*  <button id="srchBtn">{t("dashboard.search")}</button>*/}
    </div>
   


    <div id="srchresult" style={{marginBottom:"3em"}}>
    <p id="resultTitle">{t("dashboard.delivery")} </p>
{orders.length==0 || notFound?
                <p id="resname" style={{textAlign:"center"}}>{notFound? t("dashboard.noResult") :t("dashboard.noOrder")}</p>

:
orders && orders.filter((data)=>{
    if(searchTerm===""){ //for filtering products on search
        return data;
    } 
    else if(data.orderId.toString().includes(searchTerm.toLowerCase())){
   
   return data
   }
  
  
 }).map((data,index) =>  <div id="orderDiv" key={index}>
   <>
   <div className='statusDiv'>
   <button id="statusBtn" style={{cursor:"default",pointerEvents:"none"}}> <RxDotFilled size={20}/>{t("orders.done")} </button>

   </div>

            <div id="spaceBtn">
              
            <div id="dataDiv">
              
            <p id="resnum" style={i18n.language=="ar"?{display:"flex"}:null}><span># </span> { data.orderId}</p>
            <p id="orderDate" className='mobileDate'>{data.createdAt}</p>

            <p id="resname">{data.clientName}</p>
            <p id="orderPhone"><span>{t("dashboard.phone")}</span> {data.clientPhone}</p>
{orderDetails.includes(data.orderId)?
            <p id="orderPrice"><span>{t("dashboard.price")}</span> {data.price} {t("dashboard.sar")}</p>
            :null}
        </div>
<div id="actionDiv" >
    <div>
   
    <button id="startBtn" onClick={()=>showDetails(data.orderId)}>{t("dashboard.details")}</button>

    <a target="_blank" className='locLink' rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${data.clientAddress.latitude},${data.clientAddress.longitude}`}>

    <button id="orderDet" > {t("dashboard.location")}  <GrMapLocation size={16}/></button></a>

    </div>
    <p id="orderDate">{orders.createdAt}</p>
</div>
</div></>
{orderDetails.includes(data.orderId)?

<>

<p id="address"><span>{t("dashboard.address")}</span>{i18n.language=="ar"?data.clientAddress.arAddress : data.clientAddress.enAddress}</p>

<div style={{display:"flex",alignItems:"end"}}>
<div id="timeline">
<RiRecordCircleFill className="circleActive" size={40} color="#ED9434"/>
<div className='line'></div>
<IoCheckmarkCircle size={40} color="#ED9434"/>
<div className='line' style={{background:"#CECDCB"}}></div>

<RiRecordCircleFill size={40} color="#CECDCB"/>

</div>
<div id="timelineData">
    <div style={{marginTop:"0"}}>
    <p className='timeTtitle'>{t("orders.start")}</p>
    <p className='time'>{data.startOrderDelivery} </p>
    </div>
    <div id="activeStep">
    <p className='timeTtitle'>{t("orders.end")}</p>
    <p className='time'>{data.endOrderDelivery}</p>
</div>
<div >
    <p className='timeTtitle'> {t("orders.time")}</p>
    <p className='time'>{data.orderDuration}</p></div>
</div>

        </div>
        </>:null
}
        </div>)}


        
    </div>


</div>}</div>
    )
}
export default Orders;
