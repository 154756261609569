import AxiosProvider from "./AxiosProvider";
export const login = (values,lang) => {
    return AxiosProvider.post('login', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },params:{
            lang: lang
        }})
} 
export const search = (number,token) => {
    return AxiosProvider.get("order-search",{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token

        },params:{
            phoneOrOrderId: number
        }})
} 
export const start = (id,token) => {
    return AxiosProvider.post(`start-order/${id}`,null,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token

        }})
} 
export const end = (data,id,token,lang) => {
    return AxiosProvider.post(`end-order/${id}`,data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token

        },params:{
            lang: lang
        }})
} 

export const getAllOrders = (token) => {
    return AxiosProvider.get("driver-orders",{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token

        }})
} 
export const getCurrentOrder = (token) => {
    return AxiosProvider.get("current-order",{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token

        }})
} 