import '../styles/login.css';
import background from "../assets/car.svg"
import logo from "../assets/logo.svg"

import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useFormik} from "formik";
import { DotLoader } from 'react-spinners'
import { login } from '../api';
import {BsGlobe} from"react-icons/bs"
function Login() {
    const [hidden,setHidden]=useState(false);
    const { t, i18n } = useTranslation();

let history= useNavigate()
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [language, SetLanguage]=useState("ar");

    const [loading, setLoading]=useState(false);
    
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    
    
    
  
    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 
    const Schema= yup.object().shape({
    phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
    password: yup.string().min(6,("validation.at_least")).required(("validation.password_required")),
    
    })
    
    
    const formik = useFormik({
    initialValues:{
      phone:"",
      password:"",
    },
    validationSchema: Schema,
    onSubmit,
    
    });
    
    useEffect(()=>{
      const data= window.localStorage.getItem('LANGUAGE');
     

      if (data!==null){
        i18n.changeLanguage(data);
        SetLanguage(data);
      
     }  
     if(i18n.language==="ar"){
      document.getElementById("root").style.direction="rtl"
      document.title="مطعم شاويز"
  }else {
      document.title="Shawees Restaurant"
      document.getElementById("root").style.direction="ltr"

  }
    },[SetLanguage ]);


    const changeLanguage = (language) => {
      console.log(language)
      i18n.changeLanguage(language);
      localStorage.setItem("LANGUAGE",language);
      SetLanguage(language);
 
    if(language==="en"){
    document.getElementById("root").style.direction="ltr";
    document.title="Shawees Restaurant"

  
    }
    else{
      document.title="مطعم شاويز"

      document.getElementById("root").style.direction="rtl";
    }

  
    };


   
   


        //logging in takes username and password
        //if valid user data is stored and user is logged in

        async function onSubmit(event){
          setLoading(true)
          const data={
            phone: formik.values.phone,
            password: formik.values.password,
          }
          login(data,i18n.language).then((response) => {
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("name", response.data.data.name);

history("/dashboard")
          }).catch((err)=>{

                  if(err.response.data.data=="Unauthenticated"){
                    setMsgAr("هناك خطأ في رقم الهاتف او كلمة المرور");
                    setMsgEn("The phone number or password are incorrect");
                      setErrorMsgModel(true);
                      setLoading(false) 
                  }  
                  else{    
          setMsgAr(err.response.data.data);
                 setMsgEn(err.response.data.data);
                   setErrorMsgModel(true);
                   setLoading(false) }
                
        })
    }

    
  

  
    return (
//style={{ backgroundImage: `url(${background})` }}
      <div className={i18n.language=="ar"?"login":" login loginEn"}>
       <div className='container-fluid'>
         <div className='row'>
         <div className='col-sm-6 loginBackg' style={{ backgroundImage: `url(${background})` }}>

</div>
<div className='col-sm-6 formContainer'>
  <div style={{flex:1}}>
    <div id="languageBtn">
    <button onClick={()=>changeLanguage(language=="ar"?"en":"ar")} ><BsGlobe/> {language=="ar"?"en":"ar"} </button>

    </div>
  <div className='loginLogo' style={{ backgroundImage: `url(${logo})` }}>
  </div>
  <p id="welcome">{t("login.hello")}</p>
  <p id="loginTxt">{t("login.again")}</p>
  <form className='loginForm' onSubmit={formik.handleSubmit}>
  <label>{t("login.phone")}</label>

  <input type="text" maxLength={10}  placeholder={t("login.enter")} value={formik.values.phone} id="phone" name="phone"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
  <label>{t("login.password")}</label>
  <input id="pass" type="password"  value={formik.values.password}  name="password" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>
  <div className='forgetDiv'>
 {/*   <div>
    <input type="checkbox"/>

      <label>{t("login.remember")}</label>
    </div>*/}
    <p style={{display:"none"}}>{t("login.forget")}</p>
  </div>

  {i18n.language=="ar"?
<span  style={{marginTop:"1em",textAlign:"center"}}>{errorMsgModel? msgAr:null}</span>:
<span  style={{marginTop:"1em",textAlign:"center"}}>{errorMsgModel? msgEn:null}</span>}

{loading?<div className="loaderDiv" style={{height: "100px"}}>

    <DotLoader size={30} color="#ED9434"/></div>:
  <button type='submit' id="loginBtn" >{t("login.login")}</button>}
</form>
</div>
</div>
         </div>
       </div></div>
    );
  }
  
  export default Login;
  
  