import './App.css';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Orders from './screens/Orders';
import { BrowserRouter,Routes,Route,Outlet } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Routes>
    <Route exact path="/" element={<Login />}/>
    <Route exact path="/dashboard" element={<Dashboard />}/>
    <Route exact path="/orders" element={<Orders />}/>

  </Routes>

    </div>
    </BrowserRouter>
  );
}

export default App;
