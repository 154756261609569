import logo from "../assets/logo.svg"
import defaultImage from "../assets/defaultImage.jpeg"
import { FiSettings } from "react-icons/fi";
import { FiSearch,FiLogOut } from "react-icons/fi";
import { useState,useEffect } from 'react';
import { HiOutlineMenuAlt1} from "react-icons/hi";
import { GrFormClose} from "react-icons/gr";
import { BiLayer} from "react-icons/bi";
import { RiHome3Line} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {BsGlobe} from"react-icons/bs"
import { useTranslation } from 'react-i18next';
import Logout from "./Logout";
function Navbar() {
    const { t, i18n } = useTranslation();
    const [language, SetLanguage]=useState("ar");
    const [modal, setModal]=useState(false);

    const userName= localStorage.getItem("name")

    const [toggle,setToggle]=useState(false)
let history=useNavigate()


useEffect(()=>{
    const data= window.localStorage.getItem('LANGUAGE');
   

    if (data!==null){
      i18n.changeLanguage(data);
      SetLanguage(data);
    
   }  
   if(i18n.language==="ar"){
    document.getElementById("root").style.direction="rtl"
    document.title="مطعم شاويز"
}else {
    document.title="Shawees Restaurant"
    document.getElementById("root").style.direction="ltr"

}
  },[SetLanguage ]);


  const changeLanguage = (language) => {
    console.log(language)
    i18n.changeLanguage(language);
    localStorage.setItem("LANGUAGE",language);
    SetLanguage(language);

  if(language==="en"){
  document.getElementById("root").style.direction="ltr";
  document.title="Shawees Restaurant"

  }
  else{
    document.getElementById("root").style.direction="rtl";
    document.title="مطعم شاويز"

  }


  };
function logout(){
  localStorage.removeItem("token")
  localStorage.removeItem("name")

  history("/")
} 
  return (
  <>
  {modal?
  <Logout logout={logout} setModal={setModal}/>:null}
    <div id="navbar" className={i18n.language=="en"?"navbarEn":null}>
        <div className='flexRow'>
  <div onClick={()=>history("/dashboard")} id='navLogo' style={toggle?{ backgroundImage: `url(${logo})`, cursor:"pointer" }:{ backgroundImage: `url(${logo})`, cursor:"pointer",zIndex:"20"}}>
            
            </div>
<NavLink to="/dashboard" className='home'>{t("dashboard.home")}</NavLink>
<NavLink to="/orders">{t("dashboard.prev")}</NavLink>
        </div>
<div className='flexRow'>
<div id='usrImg' style={{ backgroundImage: `url(${defaultImage})` }}>
                         </div>   
                         <div className="userName" style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}} id="flexName">
        <p >{userName}</p>

        </div> 

        <button id="globe" onClick={()=>changeLanguage(language=="ar"?"en":"ar")}><BsGlobe  size={18}  color='#667085'/> {language}</button>

  <button onClick={()=>setModal(true)} id="logoutBtn"> <FiLogOut color='#ED9434' size={18}/> </button>

        </div>
    </div>
    <div id="navbarMobile" className={i18n.language=="en"?"navbarEn":null}>
        <button onClick={()=>setToggle(true)}> <HiOutlineMenuAlt1 color='#667085' size={30} /></button>
   
    <div onClick={()=>history("/dashboard")}  id='navLogo' style={toggle?{ backgroundImage: `url(${logo})`, cursor:"pointer" }:{ backgroundImage: `url(${logo})`, cursor:"pointer",zIndex:"20"}}>
    </div>
<div  className={toggle?'sideNav ':'sideNav navClosed'}>

</div>
<button className={toggle?'closeNav ':'closeNav closeNavHidden'} onClick={()=>setToggle(false)} >
<GrFormClose   size={35} />
</button>

<div className={toggle?'mobileLinks':'mobileLinksClose mobileLinks'}>
<div id='navLogo' onClick={()=>history("/dashboard")}  style={{ backgroundImage: `url(${logo})` }}> </div>
{/*
<div id="srchInput" className='navSearch'>
        <FiSearch size={20}  color='#667085'/>

<input  placeholder={t("dashboard.search")}/>

  </div>*/}
        <ul id="navList">
    <li >
    <NavLink to="/dashboard">
    <RiHome3Line size={20} /> {t("dashboard.home")}
        </NavLink>  
    </li>
    <li>
    <NavLink to="/orders"> <BiLayer size={20} /> {t("dashboard.prev")} </NavLink>
    </li>
   {/* <li>
    <a href='/'> <FiSettings size={20} /> {t("dashboard.settings")} </a>
  </li>*/}
    <li>
    <button style={{padding:"8px 10px"}} id="globe" onClick={()=>changeLanguage(language=="ar"?"en":"ar")}><BsGlobe  size={18}  color='#667085'/> {language=="ar"?"en":"ar"}</button>

    </li>
</ul>

<div className='userNav'>
    <div id="navFlex">

<div id='usrImg' style={{ backgroundImage: `url(${defaultImage})` }}>
        </div>
        <div id="flexName">
        <p >{userName}</p>
   <p id="navEmail">Ahmed.Mohamed@gmail.com</p>

        </div> </div>
        <button onClick={()=>setModal(true)}>
        <FiLogOut color='#667085' size={20}/>

        </button>


</div>

</div>

</div></>
  );
}

export default Navbar;
