
import React, { useState,useRef } from 'react';
import failed from '../assets/failed.svg';
import { useTranslation } from 'react-i18next';

function FailedModal(props) {
  const { t ,i18n} = useTranslation();

  return (
  <>
    <div onClick={()=>{ props.setModal(false)}} className="modalOverlay"></div>
    <div   className={i18n.language=="ar"?"imgModal successModal":"imgModal successModal modalEn"}  >
       <div style={{position:'relative'}}>
        <div id="ellipse" className='failedImg'  style={{ backgroundImage: `url(${failed})` }}>

        </div>
 <p id="modalTitle">{t("validation.failed")} </p>
 <p id="modalText">{t("validation.failedTxt")} </p>

 <button id="cancel" onClick={()=>{ props.setModal(false)}}>{t("validation.accept")}</button>
   <div ></div>
          </div></div></> 
  );
}

export default FailedModal;
