import '../styles/dashboard.css';
import { useTranslation } from 'react-i18next';
import { search,start } from '../api';
import { FiSearch } from "react-icons/fi";
import { GrNotes,GrMapLocation} from "react-icons/gr";
import { useState ,useEffect} from 'react';
import Navbar from '../components/Navbar';
import UploadImg from '../components/UploadImg';
import SuccessModal from '../components/SuccessModal';
import { DotLoader } from 'react-spinners';
import { RxDotFilled} from "react-icons/rx";
import FailedModal from '../components/FailedModal';
import background from "../assets/car3.svg"
import { getCurrentOrder } from '../api';
function Dashboard() {
    const [success,setSuccess]=useState(false)
    const[modal,setModal]=useState(false)
    const[number,setNumber]=useState(null)
    const[order,setOrder]=useState(null)
    const[started,setStarted]=useState(null)
    const[ended,setEnded]=useState(false)
    const[endId,setEndId]=useState(0)
    const[failed,setFailed]=useState(false)
    const[update,setUpdate]=useState(false)

    const[startedOrder,setStartedOrder]=useState()
    const[searchLoading,setSearchLoading]=useState(true)
    const[startLoading,setStartLoading]=useState(false)
    const [orderDetails,setOrderDetails]=useState([]);
const userName= localStorage.getItem("name")
    
const token=  localStorage.getItem("token")
    const { t ,i18n} = useTranslation();

    useEffect(() => {
        setSearchLoading(true)

        getCurrentOrder(token).then((response) => {
            if(response.data.data.length==0){
                setOrder(null)
            }
            else{
                setOrder(null)
                 setEnded(false)
                setStartedOrder(response.data.data)
                setStarted(response.data.data.orderId)
            }
            setSearchLoading(false)

        }).catch((err)=>{
         

        })
       
     },[update]) 

    function searchOrder(){
        setSearchLoading(true)
        search(number,token).then((response) => {
            setOrder(response.data.data)
            setSearchLoading(false)
        }).catch((err)=>{
            setSearchLoading(false)

        })

    }
    function startOrder(number){
       
        setStartLoading(true)
        start(number,token).then((response) => {
          /*  setStartedOrder(response.data.data)
            setStarted(response.data.data.orderId)
            setStartLoading(false)
setEnded(false)*/
setUpdate(!update)
setNumber("")
setStartLoading(false)

        }).catch((err)=>{
            setFailed(true)
            setStartLoading(false)

         /*   setNumber("")
            setUpdate(!update)
            setStartLoading(false)*/

        })
    
    }

    function showDetails(id){
        let idsArr=orderDetails;
          if (idsArr.includes(id)) {
            // Item exists in array, remove it
           setOrderDetails( idsArr.filter((item) => item !== id))
          } else {
            // Item doesn't exist in array, add it
            setOrderDetails( idsArr.concat(id))
          }
       
    }
    function handleDate(orderDate){
        const isoDate = orderDate;
const date = new Date(isoDate);

const year = date.getFullYear();
const month = date.getMonth() + 1;
const day = date.getDate();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();
return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
   
    return (
<div>
    {modal?<UploadImg id={endId} setEnded={setEnded} setSuccess={setSuccess} setModal={setModal}/>:null}
    {success?<SuccessModal  setModal={setSuccess}/>:null}
    {failed?<FailedModal  setModal={setFailed}/>:null}

  <Navbar/>
    <div className={i18n.language=="ar"?null:'dashboardEn' }>
        <div className='container-fluid'>

    
        <div className='row' >

      <div className='col-6 srchCol'>
      <div className='srchContainer'>
    <div id="srchDiv"> 
        <p id="hello">{t("dashboard.hello")}   {userName} </p>
        <p id="look">{t("dashboard.check")}</p>
        <div id="srchInput">
        <FiSearch size={20}  color='#667085'/>

<input type="number" value={number} id="srchInput" onChange={(e)=>setNumber(e.target.value)}  placeholder={t("dashboard.enter")}/>
        </div>
       
        <button disabled={number=="" || number==null} onClick={searchOrder} id="srchBtn">{t("dashboard.search")}</button>
    </div>
    {order==null?
    <>
    {searchLoading?<div className="loaderDiv" style={{height: "50px",marginTop:"5em"}}>

    <DotLoader size={30} color="#ED9434"/></div>:
    null
}
    </>:
    <div id="srchresult">
        <p id="resultTitle">{t("dashboard.result")}</p>
        {searchLoading?<div className="loaderDiv" style={{height: "50px"}}>

<DotLoader size={30} color="#ED9434"/></div>:
<>
{ !(order.length==0)?
<>
{order && order.map((data,index)=>
<>{orderDetails.includes(data.orderId)?

  <div id="orderDiv">
  <div id="spaceBtn">
<div id="dataDiv">
  <p id="resnum">#{data.orderId}</p>
  <p id="orderDate" className='mobileDate'>{handleDate(data.createdAt)}</p>

  <p id="resname">{data.clientName}</p>
  <p id="orderPhone"><span>{t("dashboard.phone")}</span> {data.clientPhone}</p>

  <p id="orderPrice"><span>{t("dashboard.price")}</span> {data.price} {t("dashboard.sar")}</p>
</div>
<div id="actionDiv">
<div style={{textAlign:"end"}} className='buttonsDiv'>
    {started==data.orderId?
        <button id="statusBtn" style={{cursor:"default",pointerEvents:"none"}}> <RxDotFilled size={20}/>{t("dashboard.started")} </button>

    :
<button id="startBtn" onClick={()=>startOrder(data.orderId)}>{t("dashboard.start")}</button>}
    <button id="orderDet" onClick={()=>showDetails(data.orderId)}> {t("dashboard.details")} <GrNotes size={16}/></button>
</div>
<p id="orderDate">{handleDate(data.createdAt)}</p>
</div>
</div>
<p id="address"><span>{t("dashboard.address")}</span>{i18n.language=="ar"?data.clientAddress.arAddress : data.clientAddress.enAddress}</p>
</div>

       : <div id="resultDiv">
        <div>
            <p id="resnum">#{data.orderId}</p>
            <p id="orderDate" className='mobileDate'>{handleDate(data.createdAt)}</p>

            <p id="resname"> {data.clientName} </p>
            <p id="resphone">{data.clientPhone}</p>

        </div>
<div id="actionDiv">
    <div style={{textAlign:"end"}} className='buttonsDiv'>
    {started==data.orderId?
        <button id="statusBtn" style={{cursor:"default",pointerEvents:"none"}}> <RxDotFilled size={20}/>{t("dashboard.started")} </button>

    :
    <button id="startBtn" onClick={()=>startOrder(data.orderId)}>{t("dashboard.start")}</button>}
    <button id="orderDet" onClick={()=>showDetails(data.orderId)}> {t("dashboard.details")} <GrNotes size={16}/></button>
    </div>
    <p id="orderDate">{handleDate(data.createdAt)}</p>
</div>

        </div>}</>)}
        
        </>:
                <p id="resname" style={{textAlign:"center"}}>{t("dashboard.noResult")}</p>
            }</>}
        
    </div>}

 
    {startLoading?<div className="loaderDiv" style={{height: "50px"}}>

<DotLoader size={30} color="#ED9434"/></div>:

<>{startedOrder && searchLoading==false?
    <div id="srchresult" >
        <p id="resultTitle">{t("dashboard.delivery")} </p>


        <div id="orderDiv">
    
            <div id="spaceBtn">
        <div id="dataDiv">
            <p id="resnum">#{startedOrder.orderId}</p>
            <p id="orderDate" className='mobileDate'>{handleDate(startedOrder.createdAt)}</p>

            <p id="resname">{startedOrder.clientName}</p>
            <p id="orderPhone"><span>{t("dashboard.phone")}</span> {startedOrder.clientPhone}</p>

            <p id="orderPrice"><span>{t("dashboard.price")}</span> {startedOrder.price} {t("dashboard.sar")}</p>
        </div>
<div id="actionDiv">
<div style={{textAlign:"end"}} className='buttonsDiv'>
    {ended?
        <button id="statusBtn" style={{cursor:"default",pointerEvents:"none"}}> <RxDotFilled size={20}/>{t("orders.done")} </button>

    :
    <button id="startBtn" onClick={()=>{setModal(true); setEndId(startedOrder.orderId)}}>{t("dashboard.end")}</button>}
    <a target="_blank" className='locLink' rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${startedOrder.clientAddress.latitude},${startedOrder.clientAddress.longitude}`}>

    <button id="orderDet"> {t("dashboard.location")}  <GrMapLocation size={16}/></button></a>
    </div>
    <p id="orderDate"> {handleDate(startedOrder.createdAt)}</p>
</div>
</div>
<p id="address"><span>{t("dashboard.address")}</span>{i18n.language=="ar"?startedOrder.clientAddress.arAddress : startedOrder.clientAddress.enAddress}</p>
        </div>


       
    </div>:null}</>}
    </div></div>
    <div className='col-6 removeMobile dashBoardBckg loginBackg' style={{ backgroundImage: `url(${background})`}}>

</div>   </div>
    </div>
  
</div></div>
    )
}
export default Dashboard;
